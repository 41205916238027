import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NoInformation } from "../Panel";
import helpers from "../../Utils/helpers";
import SkynetList from "../Lists/SkynetList";
import { useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes';
import SkynetIcon from '../Fundamentals/SkynetIcon';

const Subscriptions = ({ subscriptions, onButtonClick, customer, orderActions }) => {
    const [currentSubscription, setCurrentSubscription] = React.useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!subscriptions || subscriptions.length < 1)
        return <NoInformation />

    const toggleCurrentSubscription = (id) => {
        if (currentSubscription === id) {
            setCurrentSubscription("")
        } else {
            setCurrentSubscription(id)
        }
    }

    const terminateSubscription = (e, subscription) => {
        e.stopPropagation();
        onButtonClick('terminate', subscription)
    }

    const renderProductGroups = (productGroups) => {
        let addons = productGroups?.filter((group) => group.productGroupType === 0)?.map((group) => group.products)
        let products = productGroups?.filter((group) => group.productGroupType === 1)?.map((group) => group.products);

        return <div className="ml-8">
            {products.length > 0 && products.map((group, index) => {
                return <SkynetList key={`products-${index}`} title={t('subscriptions.includedproducts')} listItems={group} />
            })}
            {addons.length > 0 && <>
                <h4 className="bold pt-8">{t('subscriptions.optionalproducts')}</h4>
                {addons.map((group, index) => <SkynetList key={`addons-${index}`} listItems={group} />)}
            </>}
        </div>
    }

    const renderTerminationColumn = (subscription) => {
        if (!subscription.termination)
            return <a className="text-nowrap"
                onClick={(e) => terminateSubscription(e, subscription)}>{t('subscriptions.cancelagreement')}</a>

        return <span>{subscription.termination.reason?.reasonName} {subscription.termination.reason?.comment}</span>
    }

    const renderEditColumn = (subscription) => {
        return <div class="d-flex justify-content-center">
            <SkynetIcon icon={'vismaicon-edit'}
                onClick={() => { orderActions.getVossProductDetails(subscription.package.id, customer.customerNumber).then(navigate(routes.orderPlacement(customer.customerNumber))) }} />
        </div>
    }

    return <div className="table-responsive">
        <table className="table table-stripe table-hover">
            <thead>
                <tr key="thead">
                    <th width="200" className="text-left">{t('subscriptions.package')}</th>
                    <th width="130" className="text-left">{t('subscriptions.tier')}</th>
                    <th width="130" className="text-left">{t('subscriptions.users')}</th>
                    <th width="100" className="text-left">{t('subscriptions.purchasedate')}</th>
                    <th width="100" className="text-left">{t('subscriptions.enddate')}</th>
                    <th width="150" className="text-left">{t('subscriptions.period')}</th>
                    <th width="50" className="text-center">{t('subscriptions.edit')}</th>
                    <th width="100" className="text-left text-nowrap">{t('subscriptions.termination')}</th>
                </tr>
            </thead>
            <tbody>
                {subscriptions.map(subscription => {
                    let licenses = 0;
                    subscription.licenses?.map((license) => licenses += license.quantity);
                    return <Fragment key={subscription.id}>
                        <tr onClick={() => toggleCurrentSubscription(subscription.id)}
                            className="cursorpointer">
                            <td className={`skynet-caret-toggle${currentSubscription === subscription.id ? " toggle" : ""}`}>
                                <span className="caret mr-12 mb-2"></span>{subscription.package.name}
                            </td>
                            <td>{subscription.tier.name}</td>
                            <td>{licenses > 0 ? licenses : ""}</td>
                            <td>{helpers.formatDate(subscription.created)}</td>
                            <td>{helpers.formatDate(subscription.bindingPeriod.endDate)}</td>
                            <td>{subscription.plan.name}</td>
                            <td>{renderEditColumn(subscription)}</td>
                            <td>{renderTerminationColumn(subscription)}</td>
                        </tr>
                        {currentSubscription === subscription.id &&
                            <tr className={`skynet-top-border skynet-expandable-table-bg`}>
                                <td colSpan={8} className="pl-24 pt-12 pb-12 skynet-expandable-table-bg">
                                    {renderProductGroups(subscription.productGroups)}
                                </td>
                            </tr>
                        }
                    </Fragment>
                })}
            </tbody>
        </table>
    </div>
}
export default Subscriptions;