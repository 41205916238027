import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../../FormComponents/Input/Input";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";
import {Checkbox} from "../../FormComponents/Checkbox";

export function TargetGroupsFormContent({ form, commonActions }) {
    const onChange = (value, key) => {
        commonActions.changeMultiFormField(form.id, key, value);
    };

    const onChangeConfiguration = (value, key) => {
        onChange({...form.data.configuration, [key]: value},'configuration');
    }

    if(!form ) {
        return null;
    }

    return (<>
            <fieldset className="d-grid col-4 gap-16">
                <FormInput
                    label="Namn"
                    value={form?.data.name}
                    onChange={(value) => onChange(value, 'name')}
                    required={true}
                />
                <Checkbox label="Default" checked={form?.data.isDefault}
                          onChange={(value) => onChange(value, 'isDefault')}/>
            </fieldset>
            <fieldset className="d-grid col-4 gap-16">
                <legend>Configuration</legend>
                <Checkbox label="Require contact person" checked={form.data?.configuration?.contactPersonRequired}
                          onChange={(value) => onChangeConfiguration(value, 'contactPersonRequired')}/>
                <Checkbox label="Require customer address" checked={form.data?.configuration?.customerAddressRequired}
                          onChange={(value) => onChangeConfiguration(value, 'customerAddressRequired')}/>
                <Checkbox label="require customer number" checked={form.data?.configuration?.customerNumberRequired}
                          onChange={(value) => onChangeConfiguration(value, 'customerNumberRequired')}/>
                <Checkbox label="exclude customer from invoice file" checked={form.data?.configuration?.excludeCustomerFromInvoiceFile}
                          onChange={(value) => onChangeConfiguration(value, 'excludeCustomerFromInvoiceFile')}/>
                <Checkbox label="require expiry date" checked={form.data?.configuration?.expiryDateRequired}
                          onChange={(value) => onChangeConfiguration(value, 'expiryDateRequired')}/>
                <Checkbox label="require org. no" checked={form.data?.configuration?.organizationNumberRequired}
                          onChange={(value) => onChangeConfiguration(value, 'organizationNumberRequired')}/>
                <Checkbox label="unique org. no" checked={form.data?.configuration?.organizationNumberUnique}
                          onChange={(value) => onChangeConfiguration(value, 'organizationNumberUnique')}/>
            </fieldset>
        </>
    );
}
