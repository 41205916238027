export const UserRoles = {
    AdministratorSkynet: 1,
    AOLeads: 3,
    InternalInfo: 4,
    AOSales: 5,
    SMBSales: 6,
    Discounts: 7,
    Spcs: 9,
    EmailTemplate: 10,
    Compensation: 11,
    PartnerSales: 12,
    User: 13,
    ProductOverview: 14,
    Eloqua: 15,
    BokaMera: 16,
    OrderApproval: 17,
    SpcsSales: 18,
    Movie: 19,
    Customer: 20
}

export const Access = {
    ReadOnly: 1,
    ReadWrite: 2,
    Admin: 3
}

export const RoleAccessDescriptions = {
    AOLeads: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "Behörighet att administrera AO Leads som är skickade internt",
        [Access.Admin]: ""
    },
    InternalInfo: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: ""
    },
    AOSales: {
        [Access.ReadOnly]: "Tillgång till kundregistret",
        [Access.ReadWrite]: "Lägga ordrar på byråer med paketlösningar",
        [Access.Admin]: "Redigera de byråer som visas för Hitta Byrå kartan"
    },
    SMBSales: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "Används för säljare på företagsförsäljningen för att sätta fakturans startdatum vid orderläggning",
        [Access.Admin]: ""
    },
    Discounts: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Lägga till, redigera och ta bort VON rabattavtal"
    },
    Spcs: {
        [Access.ReadOnly]: "Behörighet att kunna se och använda Spcs specifika delar av Skynet",
        [Access.ReadWrite]: "Skicka mejl, hantera interna leads",
        [Access.Admin]: "Ändra kundinformationstyper. Hantera budget för användare samt teams"
    },
    EmailTemplate: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Redigera e-post mallar"
    },
    Compensation: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Redigera skickade kompensationer"
    },
    PartnerSales: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Redigera de partners som visas för Hitta Partner kartan"
    },
    User: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Hantera användarbehörigheter"
    },
    ProductOverview: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Redigera produkter samt produktversioner för produktöversikten "
    },
    Eloqua: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Behörighet till Eloquainställningar"
    },
    BokaMera: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Köra fakturering för BokaMera ordrar"
    },
    OrderApproval: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Godkänna, neka samt kommentera orderläggningar över kreditgränsen"
    },
    SpcsSales: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "Behörighet till ordrer- och offertläggning",
        [Access.Admin]: "Uppdatera orderbekräftelsemallar"
    },
    Movie: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Ger behörighet att skapa och redigera filmer"
    },
    Customer: {
        [Access.ReadOnly]: "",
        [Access.ReadWrite]: "",
        [Access.Admin]: "Redigera Målgrupper"
    }
};
