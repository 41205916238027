import React from "react";

export function Checkbox({
    label,
    checked,
    onChange,
    disabled,
    className,
    required = false
}) {


    return (
        <div className={`form-control checkbox skynet-form-input${required ? " required" : ""}`}>
            <input
                type="checkbox"
                id={label}
                disabled={disabled}
                checked={checked ?? checked}
                defaultValue={checked}
                onChange={(e) => onChange(e.target.checked)}
                className={className ?? "skynet-input"}
            />
            {<label htmlFor={label}>{label}</label>}
        </div>
    );
}