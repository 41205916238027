import React from 'react'
import { createRoot } from 'react-dom/client';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './Containers/App'

import commonActions from './Actions/commonActions'
import SearchReducer from './Reducers/SearchReducer'
import CustomerReducer from './Reducers/CustomerReducer'
import CommonReducer from './Reducers/CommonReducer'
import OrderReducer from './Reducers/OrderReducer'
import AppointmentReducer from './Reducers/AppointmentReducer'
import MyDashboardReducer from './Reducers/MyDashboardReducer'
import ActionLogReducer from './Reducers/ActionLogReducer'
import SettingsReducer from './Reducers/SettingsReducer'
import FileBoxReducer from './Reducers/FileBoxReducer';
import DiscountReducer from './Reducers/DiscountReducer';
import LeadsReducer from './Reducers/LeadsReducer'
import QuotationReducer from './Reducers/QuotationReducer'
import GraphReducer from './Reducers/GraphReducer';
import InternalCustomerAdminReducer from './Reducers/InternalCustomerAdmin/InternalCustomerAdminReducer';
import TargetGroupsReducer from "./Reducers/Voss/TargetGroupsReducer";
import ProductOverviewReducer from './Reducers/ProductOverviewReducer';
import BokaMeraReducer from "./Reducers/BokaMeraReducer";

const reducers = combineReducers({
    SearchReducer,
    CustomerReducer,
    CommonReducer,
    OrderReducer,
    AppointmentReducer,
    MyDashboardReducer,
    ActionLogReducer,
    SettingsReducer,
    LeadsReducer,
    QuotationReducer,
    GraphReducer,
    ProductOverviewReducer,
    FileBoxReducer,
    BokaMeraReducer,
    DiscountReducer,
    InternalCustomerAdminReducer,
    TargetGroupsReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)))
const dispatch = store.dispatch
const container = document.getElementById('root');
const root = createRoot(container);

const location = window.location;

if(location.hostname === "localhost") {
    // Uncomment for testing
    //var url = "https://skynet.stage.vismaspcs.se" + location.pathname + location.search;
    //window.location.href = url;
}

if(location.hostname === "stage.cockpit.visma.net") {
    window.location.href = "https://skynet.stage.vismaspcs.se" + location.pathname + location.search;
}

if(location.hostname === "cockpit.visma.net") {
    window.location.href = "https://skynet.vismaspcs.se" + location.pathname + location.search;
}

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

if(!window.location.href.includes('/status')) {
    dispatch(commonActions.getStartupParameters())
    dispatch(commonActions.getUserInfo())
    dispatch(commonActions.getExternalUrls())
}

const darkMode = localStorage.getItem("dark-mode");
if (!!darkMode && darkMode !== 'light-theme') {
  document.querySelector("html").classList.add("dark-theme");
} else {
  document.querySelector("html").classList.add("light-theme");
}