import { customerActionTypes as actionTypes } from '../ActionTypes/customerActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'
import commonActions from './commonActions'
import searchActions from './searchActions'

import helpers from '../Utils/helpers'
import { httpFetch } from '../Utils/httpUtils'
import * as formValidationRules from '../Constants/formValidationRules'
import leadActions from './leadActions';
import actionLogActions from './actionLogActions';
import sorting from "../Utils/sorting";
import { VonAccessRights, VonAccessRightsRoles } from '../Constants/VON/EmployeeForm/vonAccessRights'

const customerActions = {

    showCustomer(json) {
        return {
            type: actionTypes.SHOW_CUSTOMER,
            customer: json
        }
    },

    reset() {
        return {
            type: actionTypes.RESET
        }
    },

    showVonAccounts(json) {
        return {
            type: actionTypes.SHOW_VON_ACCOUNTS,
            data: json
        }
    },

    isCustomerValid(isValid) {
        return {
            type: actionTypes.CUSTOMER_DATA_STATUS,
            isValid: isValid
        }
    },

    checkAndReloadCustomer(customerNumber, dispatch, state, forceLoad) {
        const selectedCustomer = state.CustomerReducer.selectedCustomer;

        if (!selectedCustomer || forceLoad || (selectedCustomer && selectedCustomer.customerNumber !== customerNumber)) {
            dispatch(customerActions.getCustomerFromApi(customerNumber));
        }
    },

    getCustomer(customerNumber, forceLoad) {
        return (dispatch, getState) => {
            dispatch({ type: commonActionTypes.STORE_SELECTED, data: { customerNumber: customerNumber, type: "customer" } });
            return customerActions.checkAndReloadCustomer(customerNumber, dispatch, getState(), forceLoad);
        }
    },

    /// Low level action method. Use getCustomer(customerNumber) instead.
    getCustomerFromApi(customerNumber) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/getCustomer/${customerNumber}`, dispatch)
                .then(json => {
                    const result = customerActions.validateCustomer(json);
                    dispatch(customerActions.isCustomerValid(result === null));
                    dispatch(customerActions.showCustomer(json))
                    dispatch(customerActions.updateCustomerAppointmentsCount(json.openAppointments));
                    dispatch(customerActions.updateCustomerLeadsCount(json.openLeads));
                    dispatch(customerActions.updateCustomerAOLeadsCount(json.openAOLeads))
                    dispatch(commonActions.showVonWarning(!json.vonResponse))
                    dispatch(customerActions.updateCustomerCompensationHistoryCount(json.compensationHistoryCount))
                })
        }
    },

    showCustomerEditForm(customer) {
        return dispatch => {
            dispatch(commonActions.startFormEditing("customerFormData", customer, formValidationRules.CustomerForm));
        }
    },

    createCustomer(customerForm, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(customerForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            let data = customerForm.data;

            let contactPerson = {
                firstName: data.contactPersonFirstName === "" ? null : data.contactPersonFirstName,
                lastName: data.contactPersonLastName === "" ? null : data.contactPersonLastName,
                email: data.contactPersonEmail === "" ? null : data.contactPersonEmail,
                phoneNumber: data.contactPersonPhoneNumber
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    name: data.name,
                    customerNumber: data.customerNumber,
                    organizationNumber: data.organizationNumber,
                    email: data.email,
                    address1: data.address1,
                    address2: data.address2,
                    postalCode: data.postalCode,
                    city: data.postalCity,
                    electronicInvoiceOperatorCode: null,
                    electronicInvoiceAddress: data.invoiceEmail,
                    customerGroup: data.customerGroup,
                    contactPerson: contactPerson,
                    targetGroups: data.targetGroups,
                    expiryDate: data.expiryDate,
                })
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/customer/createcustomer', dispatch, httpRequest)
                .then(json => {
                    if (callback)
                        callback();
                })
        }
    },

    saveCustomer(customerForm, isCreation, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(customerForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const body = Object.assign({}, customerForm.data,
                { product: null, vonProducts: null, transactionServices: null, guidedSellingSuggestions: null })

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(body)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/customer/save', dispatch, httpRequest)
                .then(json => {
                    if (isCreation) {
                        dispatch(searchActions.emptySearchResult());
                        dispatch(searchActions.searchInputChanged(customerForm.data.organizationNumber));
                        dispatch(commonActions.showSuccess('Customer successfully created but it will take few moments for it to appear in system'));
                    } else {
                        dispatch(customerActions.getCustomer(customerForm.data.customerNumber, true));
                        dispatch(commonActions.showSuccess('Customer updated successfully'));
                    }

                    dispatch(commonActions.cancelFormEditing("customerFormData"));
                    if (callback)
                        callback();
                })
        }
    },

    beginCustomerCreation(searchInput, leadId) {
        return dispatch => {
            let data = { organizationNumber: searchInput };

            if (leadId) {
                data.leadId = leadId;
            }

            dispatch(commonActions.startFormEditing('customerSearchForm', data, formValidationRules.CustomerSearchForm));
        }
    },

    beginNewVonEmployeeCreation(form) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);
            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/GetVonUserByUsername/${form.data.email}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('newVonEmployeeForm', { email: json.email, firstName: json.firstname, lastName: json.surname, type: "0", userAlreadyExists: true }));
                })
                .catch(error => {
                    dispatch(commonActions.startFormEditing('newVonEmployeeForm', { email: form.data.email, type: "0" }));
                });
        }
    },

    addVonEmployee(newVonEmployeeForm, customerId, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({ ...newVonEmployeeForm.data, customerId })
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/customer/addVonEmployee', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Employee saved successfully'));
                    callback();
                })
        }
    },

    inactivateVonEmployee(employeeId, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({ employeeId: employeeId })
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/inactivateVonEmployee/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Employee inactivated successfully'));
                    callback();
                });
        };
    },

    deleteVonEmployee(employeeId, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'DELETE'
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/deleteVonEmployee/?employeeId=${employeeId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Employee set to be deleted'));
                    callback();
                });
        };
    },


    updateVonEmployee(editVonEmployeeForm, editVonEmployeeRolesForm, callback) {
        return dispatch => {

            const makeRequest = (url, data) => {
                const httpRequest = {
                    method: 'PUT',
                    body: JSON.stringify(data),
                };

                return httpFetch(url, dispatch, httpRequest);
            };

            dispatch(commonActions.progressLoader(true));

            const requests = [];

            if (editVonEmployeeForm.changed) {
                requests.push(
                    makeRequest('/api/customer/updateVonEmployee', editVonEmployeeForm.data)
                );
            }

            if (editVonEmployeeRolesForm.changed) {
                requests.push(
                    makeRequest(`/api/customer/updateVonEmployeeServices/`, editVonEmployeeRolesForm.data)
                );
            }

            return Promise.all(requests)
                .finally(() => {
                    dispatch(commonActions.showSuccess('Employee updated successfully'));
                    callback();
                })
        };
    },

    bulkUpdateVonEmployeeServices(vonLicenseAdministrationForm, callback) {
        return dispatch => {
    
            const removeServiceAccounts = vonLicenseAdministrationForm.data.vonAccounts
                .filter(account => account.roleId === '0')
                .map(account => ({
                    EmployeeId: account.id,
                    services: [{
                        ServiceId: vonLicenseAdministrationForm.data.service.productNumber,
                        RoleId: null,
                        RemoveService: true
                    }]
                }));
    
            const addServiceAccounts = vonLicenseAdministrationForm.data.vonAccounts
                .filter(account => account.roleId !== '0')
                .map(account => ({
                    EmployeeId: account.id,
                    services: [{
                        ServiceId: vonLicenseAdministrationForm.data.service.productNumber,
                        RoleId: account.roleId,
                        RemoveService: false
                    }]
                }));
    
            const combinedAccounts = [...removeServiceAccounts, ...addServiceAccounts];
    
            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify(combinedAccounts)
            };
    
            dispatch(commonActions.progressLoader(true));
    
            return httpFetch(`/api/customer/bulkUpdateVonEmployeeServices/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Licenses updated successfully'));
                    callback();
                });
        };
    },

    getVonEmployeeForEdit(customerNumber, vonProducts, id) {

        return dispatch => {
            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/getVonEmployee/${customerNumber}/${id}`, dispatch)
                .then(json => {

                    dispatch(commonActions.startFormEditing('editVonEmployeeForm',
                        {
                            email: json.userEmail,
                            firstName: json.userFirstName,
                            lastName: json.userLastName,
                            isPrimaryContact: json.isPrimaryContact,
                            employeeId: json.id,
                            employeeTypeId: json.employeeType.toString(),
                            onlineRoleId: json.roles.some(role => role.includes("vismaonline"))
                                ? VonAccessRightsRoles[json.roles.find(role => role.includes("vismaonline"))]
                                : VonAccessRights.Inactive
                        }));

                    const filteredServices = vonProducts.filter(product =>
                        product.vonServiceRoles && product.vonServiceRoles.some(role => json.roles.includes(role.name))
                    );

                    dispatch(commonActions.startFormEditing('editVonEmployeeRolesForm', {
                        services:
                            filteredServices.flatMap(product =>
                                product.vonServiceRoles
                                    .filter(role => json.roles.includes(role.name))
                                    .map(role => ({
                                        serviceId: product.productNumber,
                                        roleId: role.id,
                                        removeService: false
                                    }))
                            ),
                        employeeTypeId: json.employeeType.toString(),
                        employeeId: json.id
                    }));
                })
        }
    },

    getCustomerByOrganizationNumber(customerForm, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(customerForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/GetCustomerFromBusinessCheck/${customerForm.data.organizationNumber}`, dispatch)
                .then(json => {
                    if (customerForm.data.leadId) {
                        json.leadId = customerForm.data.leadId
                    }

                    dispatch(commonActions.startFormEditing("customerFormData", json, formValidationRules.CustomerForm));
                    callback();
                })
        }
    },

    getVossCustomerByOrganizationNumber(customerForm, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(customerForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/getVossCustomerFromBusinessCheck/${customerForm.data.organizationNumber}`, dispatch)
                .then(json => {
                    if (customerForm.data.leadId) {
                        json.leadId = customerForm.data.leadId
                    }

                    dispatch(commonActions.startFormEditing("vossCustomerFormData", json, formValidationRules.VossCustomerForm));
                    callback();
                })
        }
    },

    getCustomerSubscriptions(customerId) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true))
            return httpFetch(`/api/customer/getCustomerSubscriptions/${customerId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SUBSCRIPTIONS, data: json });
                })
        }
    },

    getCustomerInfoForUpdate(organizationNumber) {
        return dispatch => {

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/getCustomerInfoFromBusinessCheck/${organizationNumber}`, dispatch)
                .then(json => {

                    dispatch(commonActions.progressLoader(false))
                    dispatch(commonActions.startFormEditing("updateCustomerForm", json, formValidationRules.CustomerAddressForm));
                })
        }
    },

    startNewDiscountModal(data) {
        return (dispatch) => {
            return httpFetch(`/api/discount/getAllVonDiscounts/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_VON_DISCOUNTS, data: json });
                    dispatch(commonActions.startFormEditing("newDiscountModal", data, formValidationRules.NewDiscountForm))
                })
        }
    },

    startEducationBooking(data, courseId) {
        return dispatch => {
            dispatch(customerActions.getEducation(courseId));
            dispatch(commonActions.startFormEditing("bookEducation", data, formValidationRules.EducationForm));
            dispatch(leadActions.getMyLeadsForCustomer(data.customerNumber));
        }
    },

    sendEducationBooking(booking, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            if (booking.data.allergiesChecked === "yes") {
                if (!booking.validationRules.includes(formValidationRules.AllergiesRule))
                    booking.validationRules.push(formValidationRules.AllergiesRule);
            } else if (booking.validationRules.includes(formValidationRules.AllergiesRule)) {
                booking.validationRules.pop();
            }

            const validationResult = helpers.validateForm(booking);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(booking.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/education/book', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Booking sent successfully'));
                    dispatch(commonActions.cancelFormEditing(booking.id))
                    callback();
                })
        }
    },

    showVonAccountForm(formData, customer) {
        return dispatch => {

            dispatch(commonActions.hideAlert());

            const validationResults = customerActions.validateCustomer(customer);

            if (validationResults !== null) {
                dispatch(commonActions.showWarning('Please update customer form before creating VON account!'));
                return null;
            }

            dispatch(commonActions.startFormEditing("createVonAccount", formData, formValidationRules.CreateVonAccount));
        }
    },

    validateCustomer(customer) {
        const input = { validationRules: formValidationRules.CustomerForm, data: customer };

        const validationResults = helpers.validateForm(input);

        return validationResults;
    },

    saveVonAccount(vonAccountForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(vonAccountForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(vonAccountForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/customer/createVonAccount', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(vonAccountForm.data.customerNumber, true));
                    dispatch(commonActions.showSuccess('VON account has been created'));
                    dispatch(commonActions.cancelFormEditing("createVonAccount"));
                    callback();
                })
        }
    },

    getVonAccounts(customerNumber) {

        return (dispatch, getState) => {

            customerActions.checkAndReloadCustomer(customerNumber, dispatch, getState());

            return httpFetch(`/api/customer/getVonAccounts/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch(customerActions.showVonAccounts(json));
                })
        }
    },


    beginVonLicenseAdministrationForm(vonAccounts, service) {
        return dispatch => {

            const mappedVonAccounts = vonAccounts.map(account => {
                const role = account.roles.find(role => {
                    return service.vonServiceRoles.some(serviceRole => serviceRole.name === role.serviceRole);
                });

                const roleId = role
                    ? service.vonServiceRoles.find(serviceRole => serviceRole.name === role.serviceRole).id
                    : "0";

                return {
                    id: account.id,
                    name: account.name,
                    email: account.email,
                    roleId: roleId,
                    employeeType: account.employeeType,
                };
            });

            let data = {
                service: service,
                vonAccounts: mappedVonAccounts
            };

            dispatch(commonActions.startFormEditing('vonLicenseAdministrationForm', data));
        };
    },

    setSearchVonAccountInput(input) {
        return {
            type: actionTypes.SET_VON_ACCOUNT_SEARCH_INPUT,
            vonAccountInput: input
        }
    },

    updateCustomerAppointmentsCount(number) {
        return {
            type: actionTypes.UPDATE_CUSTOMER_APPOINTMENTS_COUNT,
            openAppointments: number
        }
    },

    updateCustomerLeadsCount(number) {
        return {
            type: actionTypes.UPDATE_CUSTOMER_LEADS_COUNT,
            openLeadsCount: number
        }
    },

    updateCustomerAOLeadsCount(number) {
        return {
            type: actionTypes.UPDATE_CUSTOMER_AOLEADS_COUNT,
            openAOLeadsCount: number
        }
    },

    selectAdminForm(id) {
        return {
            type: actionTypes.SELECT_ADMIN_FORM,
            selectedAdminForm: id
        }
    },

    getAdminFormTemplate(customerNumber, id) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("adminForm"))
            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/adminForms/getTemplate/${customerNumber}/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('adminForm', json, formValidationRules.EmailTemplate));
                })
        }
    },

    getTemplateApprovalTemplate(templateId) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("templateApprovalForm"))
            return httpFetch(`/api/adminForms/getTemplateApprovalTemplate/${templateId}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('templateApprovalForm', json, formValidationRules.EmailTemplate));
                })
        }
    },

    getFormFromEmailActionLog(emailActionLogId) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("adminForm"))
            dispatch(actionLogActions.getFormFromEmailActionLog(emailActionLogId, json =>
                dispatch(commonActions.startFormEditing('adminForm', json, formValidationRules.EmailTemplate))))
        }
    },

    sendAdminForm(adminForm, callback, successMessage) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(adminForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(adminForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/adminForms/send', dispatch, httpRequest)
                .then(json => {

                    dispatch(commonActions.showSuccess(`${successMessage ? successMessage : 'Email has been sent'}`));
                    dispatch(commonActions.cancelFormEditing(adminForm.id));
                    if (callback) {
                        callback();
                    }

                })
        }
    },

    getCustomerInvoices(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/invoice/GetCustomerInvoices/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_INVOICES, data: json });
                })
        }
    },

    getInvoiceEmailTemplate(customerNumber, solumInvoiceOCR, vbInvoiceOCR, invoiceNumber, id) {
        return (dispatch) => {
            return httpFetch(`/api/invoice/GetTemplate/${customerNumber}/${solumInvoiceOCR}/${vbInvoiceOCR}/${invoiceNumber}/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('invoiceEmail', json, formValidationRules.EmailTemplate));
                    dispatch(commonActions.showModal(true));
                })
        }
    },

    showCancelVbAgreementModal(product, customer) {
        return (dispatch, getState) => {
            return customerActions.getVbTerminationReasons(dispatch, getState().CommonReducer.dropdownlists['VbTerminationReasons'])
                .then(() => {
                    dispatch(customerActions.getTerminationEmailTemplate(customer.customerNumber, product.productNumber))
                        .then(json => {
                            dispatch(commonActions.startFormEditing('cancelVbAgreement', { ...{ ...json, to: customer.emailAddress }, product, reference: '', reasonCode: '', formName: 'CancelVbAgreement' }, formValidationRules.CancelAgreement));
                        })
                });
        }
    },

    getVbTerminationReasons(dispatch, list) {
        if (!list) {
            return httpFetch(`/api/termination/GetVbTerminationReasons`, dispatch)
                .then(json => {
                    json = [{ id: "", name: "" }, ...json]

                    const addEmptyChoice = item => {
                        if (item.secondLevelReasons) {
                            item.secondLevelReasons.forEach(item2 => addEmptyChoice(item2))
                            item.secondLevelReasons = [{ id: "", name: "" }, ...item.secondLevelReasons]
                        }
                        if (item.churnEffectReasons && item.churnEffectReasons.length > 0)
                            item.churnEffectReasons = [{ id: "", name: "" }, ...item.churnEffectReasons]
                    }
                    json.forEach(reason => addEmptyChoice(reason))
                    dispatch(commonActions.storeDropDownList('VbTerminationReasons', json));
                })
        } else {
            return Promise.resolve('success');
        }
    },

    getTerminationEmailTemplate(customerNumber, productNumber) {
        return (dispatch) => {
            return httpFetch(`/api/termination/GetTerminationEmailTemplate/${customerNumber}/${productNumber}`, dispatch);
        }
    },

    changeTerminationEmailTemplate(dispatch, customer, form, cancellationDate) {
        var apiMethod = cancellationDate === form.data.product.purchaseDate ? `GetTerminationReturnEmailTemplate` : `GetTerminationEmailTemplate`;
        return httpFetch(`/api/termination/` + apiMethod + `/${customer.customerNumber}/${form.data.product.productNumber}`, dispatch).then(json => {
            dispatch(commonActions.startFormEditing(form.id, { ...{ ...json, to: form.data.to }, product: form.data.product, reference: form.data.reference, reasonCode: form.data.reasonCode, formName: form.name, cancellationDate }, formValidationRules.CancelAgreement));
        })
    },

    cancelVbAgreement(customerNumber, form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = form.data;

            var payload = {
                reasonCode: data.reasonCode,
                secondLevelReasonCode: data.reasonCode2,
                churnEffectId: data.churnEffect,
                churnEffectExtraInfo: data.churnEffectExtraInfo,
                secondLevelReason: data.secondLevelReason,
                comment: data.comment,
                reference: data.reference,
                customerNumber,
                productNumber: data.product.productNumber,
                agreementTerminationDate: data.cancellationDate,
                agreementPurchaseDate: data.product.purchaseDate,
                terminationEmail: { body: data.body, to: data.to, from: data.from, subject: data.subject, templateType: data.templateType }
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/termination/cancelVbAgreement', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(customerNumber, true));
                    if (payload.agreementTerminationDate !== payload.agreementPurchaseDate) {
                        dispatch(commonActions.showSuccess('Agreement has been cancelled'));
                    }
                    else {
                        dispatch(commonActions.showSuccess('Request has been sent'));
                    }
                    callback();
                })
                .catch(error => {
                    callback();
                });
        }
    },

    sendInvoiceEmail(invoiceForm) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(invoiceForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(invoiceForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/invoice/send`, dispatch, httpRequest)
                .then(json => {

                    dispatch(customerActions.getCustomerInvoices(invoiceForm.data.customerNumber))
                    dispatch(commonActions.showSuccess('Invoice has been sent'));
                    dispatch(commonActions.cancelFormEditing("invoiceEmail"));
                    dispatch(commonActions.showModal(false));
                })
        }
    },

    showInvoiceCreditModal(customer, invoice) {
        return (dispatch, getState) => {
            return customerActions.getInvoiceCreditReasons(dispatch, getState().CommonReducer.dropdownlists['InvoiceCreditReasons'])
                .then(() => {
                    return dispatch(customerActions.getInvoiceCreditEmailTemplate(customer.customerNumber, invoice.invoiceNumber))
                        .then(json => {
                            const form = {
                                ...{ ...json }, customerNumber: customer.customerNumber, customerName: customer.name,
                                customerPhone: customer.phoneNumber, invoiceNumber: invoice.invoiceNumber, partialCredit: false, partialCreditReadAble: 'No', invoiceCreditReason: 0, invoiceCreditReason2: 0, invoiceCreditReasonName: 0, invoiceCreditReason2Name: 0, invoiceCreditComment: '', sum: invoice.amount, customerEmail: customer.emailAddress, contactPerson: customer.contactPerson, formName: 'sendInvoiceCreditForm'
                            }

                            dispatch(commonActions.startFormEditing('sendInvoiceCreditForm', form, formValidationRules.InvoiceCreditForm));
                        })
                });
        };
    },

    getInvoiceCreditReasons(dispatch, list) {
        if (!list) {
            return httpFetch(`/api/invoice/GetInvoiceCreditReasons`, dispatch)
                .then(json => {
                    json = [{ id: 0, name: "" }, ...json]

                    const addEmptyChoice = item => {
                        if (item.secondLevelReasons) {
                            item.secondLevelReasons.forEach(item2 => addEmptyChoice(item2))
                            item.secondLevelReasons = [{ id: 0, name: "" }, ...item.secondLevelReasons]
                        }
                    }
                    json.forEach(reason => addEmptyChoice(reason))
                    dispatch(commonActions.storeDropDownList('InvoiceCreditReasons', json));
                })
        } else {
            return Promise.resolve('success');
        }
    },

    switchInvoiceCreditValidation(partialCredit, secondReason, form) {
        return dispatch => {

            form.data.body = form.data.body_copy
            if (partialCredit && secondReason)
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.InvoiceCreditFormPartialCreditWithSecondReason))
            else if (partialCredit && !secondReason)
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.InvoiceCreditFormPartialCredit))
            else if (!partialCredit && secondReason)
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.InvoiceCreditFormWithSecondReason))
            else
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.InvoiceCreditForm))

            dispatch(commonActions.changeMultiFormField(form.id, 'partialCredit', partialCredit));
        }
    },

    getInvoiceCreditEmailTemplate(customerNumber, invoiceNumber) {
        return (dispatch) => {
            return httpFetch(`/api/invoice/getInvoiceCreditEmailTemplate/${customerNumber}/${invoiceNumber}`, dispatch)
        }
    },

    sendInvoiceCredit(customerNumber, form, body, callback) {
        return (dispatch) => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = form.data;

            var payload = {
                InvoiceCredit: {
                    invoiceCreditReason: data.invoiceCreditReason,
                    invoiceCreditReason2: data.invoiceCreditReason2 == "" ? 0 : data.invoiceCreditReason2,
                    partialCredit: data.partialCredit,
                    comment: data.invoiceCreditComment,
                    invoiceNumber: data.invoiceNumber,
                    sum: parseFloat(data.sum.toString().replace(',', '.')),
                    customerNumber: customerNumber
                },
                InvoiceCreditEmail: { body: body, to: data.to, from: data.from, subject: data.subject, templateType: data.templateType, templateId: data.templateId, customerNumber: customerNumber }
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)

            }

            dispatch(commonActions.progressLoader(true))
            return httpFetch('/api/invoice/sendinvoicecredit', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                });
        }
    },

    activateTransactionService(vonServiceId, customerNumber) {
        return (dispatch) => {

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify(vonServiceId)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/transactionServices/activate/${customerNumber}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Transaction Service Activated'));
                    dispatch(customerActions.getCustomer(customerNumber, true));
                })
        }
    },

    getVceEmailTemplate(organizationNumber, customerName) {

        return (dispatch) => {
            return httpFetch(`/api/transactionServices/VceTemplate/${organizationNumber}/${customerName}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("vceEmailTemplate", json, formValidationRules.VceTemplate));
                })
        }
    },

    activateVce(vceEmailTemplate, customerNumber, callback) {

        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(vceEmailTemplate);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(vceEmailTemplate.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/transactionServices/ActivateVce/${customerNumber}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('VCE has been activated'));
                    dispatch(customerActions.getCustomer(customerNumber, true));

                    callback();
                })
        }
    },

    updateCustomerAddress(addressForm, customerNumber) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(addressForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(addressForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/UpdateAddress/${customerNumber}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Customer address updated, please refresh'));
                    dispatch(commonActions.cancelFormEditing("updateCustomerForm"));
                })
        }
    },

    getCustomerAttendedEducations(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/education/GetAttendedEducations/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ATTENDED_EDUCATIONS, data: json });
                })
        }
    },

    getCustomerAttendedOnboardings(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/bokamera/GetAttendedOnboardings/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ATTENDED_ONBOARDINGS, data: json });
                })
        }
    },

    getOnboardingFromId(onboardingId) {
        return (dispatch) => {
            return httpFetch(`/api/bokamera/GetOnboardingById/${onboardingId}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("attendedOnboardingForm", json, null));
                })
        }
    },

    getEducations() {
        return (dispatch) => {
            return httpFetch(`/api/education/GetEducations`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EDUCATIONS, data: json });
                })
        }
    },

    getEducation(id) {
        return (dispatch) => {
            return httpFetch(`/api/education/GetEducation/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EDUCATION, data: json });
                })
        }
    },

    getCourse(id) {
        return (dispatch) => {
            return httpFetch(`/api/education/GetCourse/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_COURSE, data: json });
                })
        }
    },

    getBokaMeraServices() {
        return (dispatch) => {
            return httpFetch(`/api/bokamera/GetServices`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BOKAMERA_SERVICES, data: json });
                })
        }
    },

    getBokaMeraTimes(id, weekFromNow) {
        return (dispatch) => {

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/bokamera/GetAvailableTimes/${id}/${weekFromNow}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BOKAMERA_TIMES, data: json, bokaMeraServiceId: id, bokaMeraWeekNo: weekFromNow });
                })
        }
    },

    clearBokaMeraTimes() {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR_BOKAMERA_TIMES, data: null, bokaMeraServiceId: null, bokaMeraWeekNo: null });
        }
    },

    getBokaMeraCustomFields() {
        return (dispatch) => {
            return httpFetch(`/api/bokamera/Getcustomfields`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BOKAMERA_CUSTOMFIELDS, data: json });
                })
        }
    },

    getEmployees(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/customer/getVonAccounts/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EMPLOYEES, data: json });
                })
        }
    },

    clearEmployees() {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR_EMPLOYEES, data: null });
        }
    },

    setBokaMeraModalObject(fromDate, toDate, service, availableResources) {
        return (dispatch) => {
            dispatch({ type: actionTypes.SET_BOKA_MERA_MODAL_OBJECT, fromDate: fromDate, toDate: toDate, service: service, availableResources: availableResources })
        }
    },


    startBokaMeraModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("bokaMera", data, formValidationRules.BokaMeraForm))
        }
    },

    saveBokaMeraBooking(bokaMeraForm, callback) {
        return (dispatch) => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(bokaMeraForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            bokaMeraForm.data.serviceName = bokaMeraForm.data.service.name;

            bokaMeraForm.data.customFields.forEach(field => {
                for (let copy of Object.keys(bokaMeraForm.data)) {
                    if (copy === field.name)
                        field.value = bokaMeraForm.data[copy];
                }
            })

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(bokaMeraForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/bokamera/createBooking`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing(bokaMeraForm.id));
                    dispatch(commonActions.showSuccess(json.successMessage));
                    callback();
                })
        }
    },

    clearCustomFields(customFields) {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR_CUSTOM_FIELDS, data: customFields });
        }
    },


    updateOnboardingDetails(onboardingForm, callback) {
        return (dispatch) => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(onboardingForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/bokamera/updateOnboarding`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("attendedOnboardingForm"));
                    dispatch(commonActions.showSuccess(json.successMessage));
                    callback();
                })
        }
    },


    createConsultingForm(consultingForm) {
        return dispatch => {
            dispatch(commonActions.startFormEditing('consulting', consultingForm, formValidationRules.ConsultingForm));
        }
    },

    getConsultingProduct(id) {
        return dispatch => {
            return httpFetch(`/api/consulting/GetProduct/${id}`)
                .then(json => {
                    dispatch(commonActions.changeMultiFormField('consulting', 'product', json));
                    dispatch(commonActions.changeMultiFormField('consulting', 'totalCost', json.price));
                    dispatch(commonActions.changeMultiFormField('consulting', 'price', json.price));
                })
        }
    },

    getConsultingTotalCost(productId, hours, price) {
        hours = helpers.formatComma(hours)
        return (dispatch) => {
            return httpFetch(`/api/consulting/GetTotalCost/${productId}/${hours}/${price}`, dispatch)
                .then(json => {
                    dispatch(commonActions.changeMultiFormField('consulting', 'totalCost', json));
                })
        }
    },

    saveConsulting(consultingForm, customerNumber, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(consultingForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            var payload = {
                customerNumber,
                salesforceTicketId: consultingForm.data.salesforceTicketId,
                hoursCharged: consultingForm.data.hoursCharged ? helpers.formatComma(consultingForm.data.hoursCharged) : consultingForm.data.hoursCharged,
                price: consultingForm.data.price,
                customerReference: consultingForm.data.reference,
                productId: consultingForm.data.productId,
                salesNumber: consultingForm.data.salesNumber,
                travelCost: consultingForm.data.travelCost,
                customerEmailAddress: consultingForm.data.attendeeEmail,
                discount: consultingForm.data.discount ? consultingForm.data.discount : 0,
                lengthInHours: consultingForm.data.lengthInHours,
                courseCoordinator: consultingForm.data.courseCoordinator,
                courseDate: consultingForm.data.courseDate
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/consulting/save', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Consultation was saved!'));
                    // dispatch(commonActions.cancelFormEditing("consulting"));
                    callback();
                })
        }
    },

    getDesktopLicenses(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/desktopversion/getDesktopVersions/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_DESKTOP_LICENSES, data: json });
                })
        }
    },

    getSendDesktopLicenseTemplate(customerNumber, versionid, version, licenseKey) {
        return (dispatch) => {
            return httpFetch(`/api/desktopversion/getEmailTemplate/${customerNumber}/${versionid}/${version && version.toString()}/${licenseKey}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('sendDesktopVersion', json, formValidationRules.EmailTemplate));
                    dispatch(commonActions.showModal(true));
                })
        }
    },

    sendDesktopVersionEmail(emailForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/desktopversion/send', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showModal(false));
                    dispatch(commonActions.showSuccess('Desktop version has been sent'));
                    dispatch(commonActions.cancelFormEditing("sendDesktopVersion"));
                    if (callback)
                        callback();
                })
        }
    },

    getCollaborations(customerNumber, abortController) {
        return (dispatch, getState) => {
            return httpFetch(`/api/customer/getCollaborations/${customerNumber}`, dispatch, { signal: abortController.signal })
                .then(json => {
                    dispatch({ type: actionTypes.STORE_COLLABORATIONS, data: json });
                })
        }
    },

    clearCollaborations() {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR_COLLABORATIONS, data: null });
        }
    },

    getAoCustomers(salesNumbers) {
        return (dispatch, getState) => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(salesNumbers)
            }

            return httpFetch(`/api/customer/getAoCustomerForResponsiblePersons`, dispatch, httpRequest)
                .then(json => {
                    const list = sorting.sortListFromStorage("aoCustomers", json);
                    dispatch({ type: actionTypes.STORE_AO_CUSTOMERS, data: list });
                })
        }
    },

    clearAoCustomers() {
        return (dispatch, getState) => {
            dispatch({ type: actionTypes.STORE_AO_CUSTOMERS, data: null });
        }
    },

    getDiscounts(customerId) {
        return (dispatch) => {
            return httpFetch(`/api/customer/getCustomerDiscounts/${customerId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CUSTOMER_DISCOUNTS, data: json });
                })
        }
    },

    assignNewDiscount(form, customerId, callback) {
        return (dispatch) => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            var selectedDate = new Date(form.data.validToDate)
            var lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();

            if (selectedDate.getDate() != lastDayOfMonth) {
                dispatch(commonActions.showWarning('Valid date should be the last day of month', 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/discount/assignNewDiscount/${customerId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("newDiscountModal"));
                    this.getDiscounts(customerId);
                    callback();
                })
        }
    },

    deleteDiscount(customerId, discountId) {
        return dispatch => {
            const httpRequest = {
                method: 'DELETE'
            }
            return httpFetch(`/api/discount/deleteCustomerDiscount/${customerId}/${discountId}`, dispatch, httpRequest).then(() => {
                dispatch(customerActions.getDiscounts(customerId))
            })
        }
    },

    getVossTerminationEmailTemplate(subscription) {
        return (dispatch, getState) => {

            dispatch(commonActions.progressLoader(true));
            return customerActions.getSubscriptionTerminationReasons(dispatch, getState().CommonReducer.dropdownlists['SubscriptionTerminationReasons'])
                .then(() => {
                    httpFetch(`/api/termination/GetVossTerminationEmailTemplate/${subscription.id}`, dispatch)
                        .then(json => {
                            dispatch(commonActions.progressLoader(false));
                            dispatch(commonActions.startFormEditing('CancelVossSubscriptions', { ...json, reference: '', }, formValidationRules.CancelSubscription));
                        })
                })
        }
    },

    getSubscriptionTerminationReasons(dispatch, list) {
        return httpFetch(`/api/termination/GetVossTerminationReasons`, dispatch)
            .then(json => {
                dispatch(commonActions.storeDropDownList('SubscriptionTerminationReasons', json));
            })
    },

    generateVossTerminationRequest(form, subscription) {
        const data = form.data;

        let payload = {
            customerNumber: data.customerNumber,
            type: data.terminationType,
            periodIterationCount: data.periodIterationCount,
            invoiceLineText: [data.invoiceLineText ? data.invoiceLineText : ''],
            reason: {
                reasonId: data.reason,
                subreasonId: data.subReason,
                comment: data.comment
            },
            product: subscription.package.name,
            creditOptions: data.creditOptions,
            terminationEmail: { body: data.body, to: data.to, from: data.from, subject: data.subject, templateType: data.templateType },
            isDryRun: form.data.isDryRun
        };

        return {
            method: 'POST',
            body: JSON.stringify(payload)
        };
    },

    updateVossTerminationEmailTemplate(form, subscription, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            let httpRequest = customerActions.generateVossTerminationRequest(form, subscription);

            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/termination/GetUpdatedVossTerminationEmailTemplate/${subscription.id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.changeMultiFormField(form.id, 'body', json.body));
                    callback();
                })
                .catch(error => {
                    dispatch(commonActions.showError('Failed to update email template'));
                });
        }
    },

    cancelSubscription(form, subscription, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }
            let httpRequest = customerActions.generateVossTerminationRequest(form, subscription);

            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/termination/CancelSubscription/${subscription.id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(data.customerNumber, true));
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                })
                .catch(error => {
                    callback();
                });
        }
    },

    getChangeSaasPeriodTemplate(customerNumber, productNumber) {
        return dispatch => {
            return httpFetch(`/api/customer/changePeriodTemplate/${customerNumber}/${productNumber}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('changeSaasPeriod', json, null));
                })
        }
    },

    changeSaasPeriod(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/customer/changeSaasPeriod', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(form.data.customerNumber, true));
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                })
                .catch(error => {
                    if (error === 'BusinessWarningException') {
                        callback();
                    }
                });
        }
    },

    getSaasTerminationEmailTemplate(customerNumber, product) {
        return (dispatch, getState) => {
            return customerActions.getSaasTerminationReasons(dispatch, getState().CommonReducer.dropdownlists['SaasTerminationReasons'])
                .then(() => {
                    httpFetch(`/api/termination/GetSaasTerminationEmailTemplate/${customerNumber}/${product.productNumber}`, dispatch)
                        .then(json => {
                            const reasons = getState().CommonReducer.dropdownlists['SaasTerminationReasons']
                            dispatch(commonActions.startFormEditing('CancelSaasAgreement', { ...json, product, reference: '', reasonCode: reasons[0].id }, formValidationRules.CancelAgreement));
                        })
                })
        }
    },

    changeSaasTerminationEmailTemplate(dispatch, customer, form, cancellationDate) {
        var apiMethod = cancellationDate === form.data.product.purchaseDate ? `GetSaasTerminationReturnEmailTemplate` : `GetSaasTerminationEmailTemplate`;
        return httpFetch(`/api/termination/` + apiMethod + `/${customer.customerNumber}/${form.data.product.productNumber}`, dispatch).then(json => {
            dispatch(commonActions.startFormEditing(form.id, { ...{ ...json, to: form.data.to }, product: form.data.product, reference: form.data.reference, reasonCode: form.data.reasonCode, formName: form.name, cancellationDate }, formValidationRules.CancelAgreement));
        })
    },

    getSaasTerminationReasons(dispatch, list) {
        if (!list) {
            return httpFetch(`/api/termination/GetSaasTerminationReasons`, dispatch)
                .then(json => {
                    json = [{ id: "", name: "" }, ...json]

                    const addEmptyChoice = item => {
                        if (item.secondLevelReasons) {
                            item.secondLevelReasons.forEach(item2 => addEmptyChoice(item2))
                            item.secondLevelReasons = [{ id: "", name: "" }, ...item.secondLevelReasons]
                        }
                        if (item.churnEffectReasons && item.churnEffectReasons.length > 0)
                            item.churnEffectReasons = [{ id: "", name: "" }, ...item.churnEffectReasons]
                    }
                    json.forEach(reason => addEmptyChoice(reason))
                    dispatch(commonActions.storeDropDownList('SaasTerminationReasons', json));
                })
        } else {
            return Promise.resolve('success');
        }
    },

    cancelSaasAgreement(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = form.data;

            var payload = {
                reasonCode: data.reasonCode,
                secondLevelReasonCode: data.reasonCode2,
                churnEffectId: data.churnEffect,
                churnEffectExtraInfo: data.churnEffectExtraInfo,
                secondLevelReason: data.secondLevelReason,
                comment: data.comment,
                reference: data.reference,
                customerNumber: data.customerNumber,
                productNumber: data.product.productNumber,
                productDescription: data.product.product,
                agreementTerminationDate: data.cancellationDate,
                agreementPurchaseDate: data.product.purchaseDate,
                terminationEmail: data
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/termination/cancelSaasAgreement', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(data.customerNumber, true));
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                })
                .catch(error => {
                    callback();
                });
        }
    },

    getPendingTerminationRequests(customerNumber) {
        return dispatch => {
            return httpFetch(`/api/termination/pendingTerminationRequests/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PENDING_TERMINATION_REQUESTS, data: json });
                })
        }
    },

    getAoLeadEmailTemplate(customerNumber, templateType) {

        return (dispatch) => {
            return httpFetch(`/api/customer/AoLeadEmailTemplate/${customerNumber}/${templateType}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("internalLeadEmail", json, formValidationRules.InternalLead));
                })
        }
    },

    getInternalLeadEmailTemplate(customerNumber, templateType) {

        return (dispatch) => {
            return httpFetch(`/api/customer/internalLeadEmailTemplate/${customerNumber}/${templateType}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("internalLeadEmail", json, formValidationRules.EmailTemplate));
                })
        }
    },

    setInternalLeadsEmailTemplate(emailTemplate) {
        return (dispatch) => {
            dispatch({ type: actionTypes.SET_INTERNAL_LEAD_EMAIL_TEMPLATE, data: emailTemplate });
        }
    },

    sendInternalAoLeadEmail(emailForm, customerNumber, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/SendInternalAoLeadEmail`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been sent'));

                    callback();
                    this.getCustomer(customerNumber, true);
                })
        }
    },

    sendExternalLeadEmail(emailForm, customerNumber, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());
            emailForm.validationRules = formValidationRules.ExternalLead
            const validationResult = helpers.validateExternalForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/SendExternalLeadEmail`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been sent'));

                    callback();
                    this.getCustomer(customerNumber, true);
                })
        }
    },

    sendInternalLeadEmail(emailForm, customerNumber, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/SendInternalLeadEmail/${customerNumber}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been sent'));

                    callback();
                    this.getCustomer(customerNumber, true);
                })
        }
    },

    getRelatedCustomers(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/customer/getRelatedCustomers/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_RELATED_CUSTOMERS, data: json });
                })
        }
    },

    setSelectedWebinar(webinar) {
        return dispatch => {
            dispatch({ type: actionTypes.SET_SELECTED_WEBINAR, data: webinar })
        }
    },

    getWebinars() {
        return (dispatch) => {
            return httpFetch(`/api/customer/getWebinars`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_WEBINARS_FOR_SALES_PERSON, data: json });
                })
        }
    },

    getWebinarEmailTemplate(customerNumber, webinarId) {
        return (dispatch) => {
            return httpFetch(`/api/customer/webinarEmailTemplate/${customerNumber}/${webinarId}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("webinarEmail", json, formValidationRules.EmailTemplate))
                })
        }
    },

    sendWebinarEmail(emailForm, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/sendWebinarEmail`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Email has been sent'));

                    callback();
                })
        }
    },

    getCompanyExpenseTerminationTemplate(customerNumber, product) {
        return (dispatch, getState) => {
            return customerActions.getSaasTerminationReasons(dispatch, getState().CommonReducer.dropdownlists['SaasTerminationReasons'])
                .then(() => {
                    httpFetch(`/api/termination/GetCompanyExpenseTerminationTemplate/${customerNumber}/${product.productNumber}`, dispatch)
                        .then(json => {
                            dispatch(commonActions.startFormEditing('CancelCompanyExpenseAgreement', { ...json, product, reference: '', reasonCode: '' }, formValidationRules.CancelAgreement));
                        })
                })
        }
    },

    cancelCompanyExpenseAgreement(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = form.data;

            var payload = {
                reasonCode: data.reasonCode,
                churnEffectId: data.churnEffect,
                churnEffectExtraInfo: data.churnEffectExtraInfo,
                secondLevelReasonCode: data.reasonCode2,
                secondLevelReason: data.secondLevelReason,
                comment: data.comment,
                reference: data.reference,
                customerNumber: data.customerNumber,
                productNumber: data.product.productNumber,
                productDescription: data.product.product,
                agreementTerminationDate: data.cancellationDate,
                agreementPurchaseDate: data.product.purchaseDate,
                terminationEmail: data
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/termination/cancelCompanyExpenseAgreement', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(data.customerNumber, true));
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                })
                .catch(error => {
                    callback();
                });
        }
    },

    showCompensationModal(product, customer, aoRestriction) {
        return (dispatch) => {
            return dispatch(customerActions.getCompensationEmailTemplate(customer.customerNumber, product))
                .then(json => {
                    const form = {
                        ...{ ...json }, product, compensationReason: '', compensationMonth: '', salesforceTicketLink: '',
                        contactCustomer: aoRestriction, compensationPrice: '', compensationComment: '', customerNumber: customer.customerNumber, customerName: customer.name,
                        customerPhone: customer.phoneNumber, customerEmail: customer.emailAddress, contactPerson: customer.contactPerson, formName: 'compensation'
                    }

                    if (!aoRestriction)
                        dispatch(commonActions.startFormEditing('compensation', form, formValidationRules.CompensationAllFields));
                    else {
                        dispatch(commonActions.startFormEditing('compensation', form, formValidationRules.CompensationContactCustomer));
                        dispatch(commonActions.changeMultiFormField('compensation', 'to', form.defaultToAddresses[1].name));
                    }
                })
        };
    },

    switchCompensationValidation(value, form) {
        return dispatch => {

            form.data.body = form.data.body_copy
            if (value === true)
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.CompensationContactCustomer))
            else
                dispatch(commonActions.startFormEditing(form.id, form.data, formValidationRules.CompensationAllFields))

            dispatch(commonActions.changeMultiFormField(form.id, "validationAllFields", value));
        }
    },

    switchToEmailAddress(contactCustomer, form) {
        return dispatch => {
            if (contactCustomer === true && form.data.defaultToAddresses[1] != undefined)
                dispatch(commonActions.changeMultiFormField(form.id, 'to', form.data.defaultToAddresses[1].name));
            else
                dispatch(commonActions.changeMultiFormField(form.id, 'to', form.data.defaultToAddresses[0].name));
        }
    },

    getNewCompensationPrice(product, months, customerNumber) {
        return (dispatch) => {
            dispatch(commonActions.changeMultiFormField('compensation', 'compensationPrice', ''));
            dispatch(commonActions.progressLoader(true))
            if (product.module !== null) {
                return httpFetch(`/api/customer/GetVonCompensationSum/${product.moduleNumber}/${customerNumber}/${product.period}/${product.additionalUsers || false}/${months}`, dispatch)
                    .then(json => {
                        dispatch(commonActions.changeMultiFormField('compensation', 'compensationPrice', json));
                    })
            }
            else {
                if (product.api === "")
                    product.api = false;
                if (product.api === "X")
                    product.api = true;
                return httpFetch(`/api/customer/GetVbCompensationSum/${product.productNumber}/${product.additionalUsers}/${product.api || false}/${months}`, dispatch)
                    .then(json => {
                        dispatch(commonActions.changeMultiFormField('compensation', 'compensationPrice', json));
                    })
            }
        }
    },

    getCompensationsForCustomer(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/customer/getCompensationsForCustomer/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_COMPENSATIONS_FOR_CUSTOMER, data: json });
                })
        }
    },

    getCompensationResultsDropDown() {
        return (dispatch) => {
            return httpFetch(`/api/customer/GetCompensationResultsDropDown/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_COMPENSATIONS_RESULTS_DROPDOWN, data: json });
                })
        }
    },

    getCompensationEmailTemplate(customerNumber, product) {
        return (dispatch) => {
            return httpFetch(`/api/customer/getCompensationEmailTemplate/${customerNumber}/${product.product}`, dispatch)
        }
    },

    sendCompensation(product, customerNumber, form, body, callback) {
        return (dispatch) => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = form.data;

            if (product.api === "")
                product.api = false;
            if (product.api === "X")
                product.api = true;

            var payload = {
                contactCustomer: data.contactCustomer,
                compensationReason: data.compensationReason,
                compensationSubReason: data.compensationSubReason,
                months: data.compensationMonth,
                compensationComment: data.compensationComment,
                salesforceTicketLink: data.salesforceTicketLink,
                customerNumber: customerNumber,
                product: { moduleNumber: product.moduleNumber, period: product.period, users: product.additionalUsers, productNumber: product.productNumber, api: product.api, name: product.product },
                compensationEmail: { body: body, to: data.to, from: data.from, subject: data.subject, templateType: data.templateType }
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)

            }

            dispatch(commonActions.progressLoader(true))
            return httpFetch('/api/customer/sendcompensation', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                });
        }
    },

    updateCustomerCompensationHistoryCount(number) {
        return {
            type: actionTypes.UPDATE_CUSTOMER_COMPENSATION_HISTORY_COUNT,
            compensationHistoryCount: number
        }
    },

    showAddCompanyExpenseAdminUserEditForm(data) {
        return dispatch => {
            dispatch(commonActions.startFormEditing("addCompanyExpenseAdminUser", data, formValidationRules.CompanyExpenseAdminCreateForm));
        }
    },

    addCompanyExpenseAdminUser(form, callback) {
        return dispatch => {
            var data = form.data;

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(data)
            }

            return httpFetch('/api/companyexpense/createCompanyExpenseAdminUser', dispatch, httpRequest)
                .then(json => {
                    dispatch(customerActions.getCustomer(data.customerNumber, true));
                    dispatch(commonActions.showSuccess('Request has been sent'));
                    callback();
                })
                .catch(error => {
                    callback();
                });
        }
    },

    getCampaignsForCustomer(customerNumber) {
        return (dispatch) => {
            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/campaign/GetCampaignsForCustomer/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CAMPAIGNS_FOR_CUSTOMER, data: json });
                })
        }
    },

    sendCampaignEmail(emailId, contactId, customerNumber) {
        return (dispatch) => {

            var data = {
                emailId: emailId,
                contactId: contactId
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(data)
            }

            return httpFetch(`/api/campaign/resendemail`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Email has been sent'));
                    dispatch(customerActions.getCampaignsForCustomer(customerNumber));
                })
        }
    },

    clearCampaignsForCustomer() {
        return {
            type: actionTypes.STORE_CAMPAIGNS_FOR_CUSTOMER,
            data: null
        };
    },

    getVismaFinanceProducts(customerNumber) {
        return (dispatch) => {
            dispatch({ type: actionTypes.SET_VISMA_FINANCE_PRODUCTS_LOADING, data: true });
            return httpFetch(`/api/vismafinance/GetProducts/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_VISMA_FINANCE_PRODUCTS, data: json });
                    dispatch({ type: actionTypes.SET_VISMA_FINANCE_PRODUCTS_LOADING, data: false });
                })
        }
    },

    getCompanyExpenseProducts(customerNumber, organizationNumber) {
        return (dispatch) => {
            dispatch({ type: actionTypes.SET_COMPANY_EXPENSE_PRODUCTS_LOADING, data: true });
            return httpFetch(`/api/CompanyExpense/GetProducts/${customerNumber}/${organizationNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_COMPANY_EXPENSE_PRODUCTS, data: json });
                    dispatch({ type: actionTypes.SET_COMPANY_EXPENSE_PRODUCTS_LOADING, data: false });
                })
        }
    },

    getBlikkProducts(customerNumber) {
        return (dispatch) => {
            dispatch({ type: actionTypes.SET_BLIKK_PRODUCTS_LOADING, data: true });
            return httpFetch(`/api/Blikk/GetProducts/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BLIKK_PRODUCTS, data: json });
                    dispatch({ type: actionTypes.SET_BLIKK_PRODUCTS_LOADING, data: false });
                })
        }
    },

    saveCustomerConnections(data, callback) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(data)
        }

        return dispatch => {
            httpFetch(`/api/customer/saveconnectedvoncustomers/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Connected customers VON successfully updated!'));
                    dispatch(commonActions.cancelFormEditing("customerFormData"));
                    dispatch(customerActions.getCustomer(data.currentCustomer, true));
                    callback()
                })
        }
    },

    getAOCustomerInfo(customerNumber) {
        return dispatch => {
            return httpFetch(`/api/customer/getAoCustomerInfo/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_AO_CUSTOMER_INFO, data: json });
                })
        }
    },

    startAOCustomerInfoEdit(customerNumber) {
        return dispatch => {

            dispatch(commonActions.progressLoader(true))

            return httpFetch(`/api/customer/getAoCustomerInfo/${customerNumber}`, dispatch)
                .then(json => {

                    dispatch(commonActions.progressLoader(false))
                    dispatch(commonActions.startFormEditing("updateAOCustomerInfoModal", json, formValidationRules.AoCustomerInfo));
                })
        }
    },

    saveAoCustomerInfo(form, callback) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(form.data)
        }

        return dispatch => {
            httpFetch(`/api/customer/saveAoCustomerInfo/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('AO customer info successfully updated!'));
                    dispatch(customerActions.getAOCustomerInfo(form.data.customerNumber, true));
                    callback()
                })
        }
    },

    updateDirectoryCustomer(form, callback) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(form.data)
        }

        return dispatch => {
            httpFetch(`/api/customer/updateDirectoryCustomer/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Directory customer info successfully updated!'));
                    callback()
                })
        }
    },

    getSentMovies(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/movie/getSentMovies/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SENT_MOVIES, data: json });
                })
        }
    }
}

export default customerActions;