import { targetGroupActionTypes as actionTypes } from "../../ActionTypes/Voss/targetGroupActionTypes";
import { initialState } from "../CommonReducer";

export default function TargetGroupsReducer(state = {initialState}, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_TARGET_GROUPS:
            newState = { targetGroups: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}