import { Dropdown } from "../../../FormComponents/Dropdown";

export function VonServiceSelection({ services, selectedService, onChange }) {

    const options = services.map(product => {
        return {
            id: product.productNumber,
            name: product.product
        }
    });

    const selectService = (value) => {
        const selectedService = services.find(product => product.productNumber === value);
        onChange(selectedService);
    }

    return <div>
        <fieldset className="d-grid col gap-16 mb-0">
            <Dropdown label={"Tjänst"} options={options} value={selectedService} onChange={(value) => selectService(value)}/>
        </fieldset>
    </div>
}