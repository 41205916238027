import React from 'react';
import { Route, Routes } from 'react-router-dom'
import TargetGroupsContainer from "../../../Containers/Settings/Voss/TargetGroupsContainer";
import TargetGroupsEditContainer from "../../../Containers/Settings/Voss/TargetGroupsEditContainer";

function TargetGroupsLayout() {

    return <Routes>
        <Route path={'/create'} element={<TargetGroupsEditContainer />} />
        <Route path={'/edit'} element={<TargetGroupsEditContainer />} />
        <Route index element={<TargetGroupsContainer />} />
    </Routes>
}

export default TargetGroupsLayout;
