import {connect} from "react-redux";
import Card from "../../../Components/Card/Card";
import routes from "../../../Constants/routes";
import {useNavigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import TargetGroupActions from "../../../Actions/Voss/targetGroupActions";
import {TargetGroupsTable} from "../../../Components/Voss/TargetGroups/TargetGroupsTable";
import {useEffect} from "react";

function TargetGroupsContainer ({ targetGroupsActions, targetGroups, }){
    const navigate = useNavigate();

    useEffect(() => {
        targetGroupsActions.getTargetGroups();
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Header.Title>Målgrupper</Card.Header.Title>
            </Card.Header>
            <Card.Content>

                <div className="mb-12">
                    <button className="btn btn-primary"
                            onClick={() => navigate(routes.vossTargetGroupsCreate)}>Skapa målgrupp
                    </button>
                </div>
                <div>
                    <TargetGroupsTable targetGroups={targetGroups}/>
                </div>
            </Card.Content>
        </Card>
    );
}



function mapDispatchToProps(dispatch) {
    return {
        targetGroupsActions: bindActionCreators(TargetGroupActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        targetGroups: state.TargetGroupsReducer.targetGroups
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroupsContainer);
