import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";
import { useTranslation } from "react-i18next";

export function TerminationReasonsTable({ ...props }) {
    const { terminationReasons } = props;
    const { t } = useTranslation();
    return <table className="table table-stripe skynet-table-no-divider table-active">
        <thead>
            <tr>
                <th className="col-8">{t('terminationreasonstable.reason')}</th>
                <th className="col-1">{t('terminationreasonstable.edit')}</th>
                <th className="col-1">{t('terminationreasonstable.delete')}</th>
            </tr>
        </thead>
        <tbody>
            {terminationReasons && terminationReasons.map((reason, index) => (
                <TerminationReasonRow key={index} reason={reason} {...props} />
            ))}
        </tbody>
    </table>
};

function TerminationReasonRow({ ...props }) {
    const { reason, terminationReasonsActions } = props;

    const [active, setActive] = useState(false);
    const navigate = useNavigate();

    const hasSubReasons = (reason) => {
        return reason.subReasons && reason.subReasons.length > 0;
    };

    useEffect(() => {
        if (!hasSubReasons(reason))
            setActive(false);
    }, [reason.subReasons]);

    const handleRowClick = () => {
        if (hasSubReasons(reason)) {
            setActive(!active);
        }
    };

    const navigateToEdit = (reasonId) => {
        const params = new URLSearchParams();
        params.append("ReasonId", reasonId);
        navigate(routes.icaTerminationReasonsEdit + "?" + params.toString())
    }

    return <>
        <tr className={active && 'active'} onClick={handleRowClick}>
            <td className={active && "bold"}>
                <span className={`caret caret-lg ${!hasSubReasons(reason) && 'caret-disabled'} ${active && 'open'}`}></span><div className="ml-24 d-inline-block">{reason.name}</div>
            </td>
            <td>
                <SkynetIcon icon={'vismaicon-edit'} onClick={() => navigateToEdit(reason.id)} />
            </td>
            <td>
                <SkynetIcon icon={'vismaicon-delete'} onClick={() => terminationReasonsActions.deleteTerminationReason(reason.id, () => terminationReasonsActions.getTerminationReasons())} />
            </td>
        </tr>
        {active && reason.subReasons.map((subReason, subIndex) => (
            <tr key={subIndex}>
                <td className="pl-48">
                    {subReason.name}
                </td>
                <td>
                </td>
                <td>
                    <SkynetIcon icon={'vismaicon-delete'} onClick={() => terminationReasonsActions.deleteSubTerminationReason(subReason.id, reason.id, () => terminationReasonsActions.getTerminationReasons())} />
                </td>
            </tr>
        ))}
    </>
};