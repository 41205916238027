import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import { editEmployee, newEmployee, VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";
import { Dropdown } from "../../../FormComponents/Dropdown";

export default function VonEditEmployeeTypeForm({ data, onChange }) {

    const onFieldChange = (value) => {

        if (value !== VonEmployeeTypes.User) {
            onChange('editVonEmployeeForm', null, "newMainContactId");
            onChange('editVonEmployeeForm', VonAccessRights.User, "onlineRoleId");
        }
        onChange('editVonEmployeeForm', value, 'employeeTypeId');
    }

    const isConversion = data.employeeTypeId == VonEmployeeTypes.Conversion.id;
    const disabled = isConversion || data.isPrimaryContact;

    return <div>
        <fieldset className="d-grid col gap-16 mb-0" disabled={disabled}>
            <legend>Byt typ av användare</legend>
            <Dropdown label={'Typ av användare'} options={isConversion ? newEmployee : editEmployee}  initialValue={data.employeeTypeId} value={data.employeeTypeId} onChange={(value) => onFieldChange(value)} />
        </fieldset>
    </div>
}