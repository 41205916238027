import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Card from "../../../Components/Card/Card";
import React, {useEffect} from "react";
import commonActions from "../../../Actions/commonActions";
import targetGroupActions from "../../../Actions/Voss/targetGroupActions";
import {TargetGroupsFormContent} from "../../../Components/Voss/TargetGroups/TargetGroupsFormContent";
import Loader from "../../../Components/Loader";
import {useTranslation} from "react-i18next";
import routes from "../../../Constants/routes";
import {useNavigate, useSearchParams} from "react-router-dom";
import SkynetIcon from "../../../Components/Fundamentals/SkynetIcon";

function TargetGroupsEditContainer({ targetGroupActions, commonActions, form }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const id = searchParams.get('Id');

    useEffect(() => {
        targetGroupActions.getTargetGroupForEdit(id);
    }, []);

    const cancelAction = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.vossTargetGroups));
    }

    const saveAction = () => {
        targetGroupActions.saveTargetGroup(form, () => navigate(routes.vossTargetGroups));
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>{`${form?.data.id ? 'Edit' : 'Create'} target group`}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <TargetGroupsFormContent form={form} commonActions={commonActions} targetGroupActions={targetGroupActions}/>
        </Card.Content>
        <Card.Footer>
            {form?.data.id &&
                <button type="button" className="btn btn-primary btn-destructive"
                                         onClick={() => targetGroupActions.deleteTargetGroup(form.data.id, () => navigate(routes.vossTargetGroups))}>
                    {"Delete"}
                    <SkynetIcon icon="vismaicon vismaicon-delete"/>
                </button>
            }

            <Card.Footer.Buttons>
                <>
                    <Loader/>
                    <button type="button" className="btn btn-default"
                            onClick={cancelAction}>{t('common.cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={saveAction}>{t('common.save')}</button>
                </>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card>
}

function mapDispatchToProps(dispatch) {
    return {
        targetGroupActions: bindActionCreators(targetGroupActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.TargetGroupForm,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroupsEditContainer);