import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import helper from '../../Utils/helpers'

import './SearchResults.css'

const SearchResults= ({state, dropdownlists, clickEvent}) => {

    const { t } = useTranslation();

    if (!state.searchResult) {
      return null;
    }

    if (state.searchResult.length === 0) {
      return (
        <div className="searchResults noResults">
-          <h3>No results found for <strong>{state.searchResultInput}</strong>. <Link to={{ pathname:'/new-customer', search: `input=${state.searchResultInput}` }}>Create new customer?</Link></h3>
        </div>
      );
    }
   
    return (
        <div className="searchResults" style={{zIndex:"1"}}>
          <h3>{ state.searchResult.length } {t('searchresult.resultsfor')} <strong>{state.searchResultInput}</strong></h3>
          <div className="table-responsive">
          <table className="table table-stripe table-hover">
            <thead>
                <tr>
                  <th width="200" className="text-right">{t('searchresult.organizationnumber')}</th>
                  <th width="200" className="text-right">{t('searchresult.customernumber')}</th>                  
                  <th>{t('searchresult.name')}</th>
                  <th>{t('searchresult.customergroup')}</th>
                  <th>{t('searchresult.customertype')}</th>
                  <th>{t('searchresult.status')}</th>
                  <th>{t('searchresult.phonenumber')}</th>
                </tr>
            </thead>
            <tbody>
            {
              state.searchResult.map(customer =>
                <tr key={customer.id} onClick={() => clickEvent(customer.customerNumber) } className="cursorpointer">
                  <td className="text-right">{customer.organizationNumber}</td>
                  <td className="text-right">{customer.customerNumber}</td>
                  <td className="text-left">{customer.name}</td>
                  <td className="text-left">{helper.getDropdownItemName(dropdownlists?.CustomerGroup, customer.group)}</td>
                  <td className="text-left">{customer.targetGroup ? helper.getDropdownItemName(dropdownlists?.TargetGroup, customer.targetGroup) : helper.getDropdownItemName(dropdownlists?.CustomerType, customer.type) }</td>
                  <td className="text-left">{helper.getDropdownItemName(dropdownlists?.CustomerStatus, customer.status)}</td>
                  <td className="text-left">{customer.phoneNumber}</td>
                </tr>
              )
            }
            </tbody>
          </table>
          </div>
        </div>
      );
  }

  export default SearchResults;