import { bindActionCreators } from "redux";
import terminationReasonsActions from "../../../../Actions/InternalCustomerAdmin/terminationReasonsActions";
import { useEffect } from "react";
import { connect } from "react-redux";
import Card from "../../../../Components/Card/Card";
import { useNavigate } from "react-router-dom";
import routes from "../../../../Constants/routes";
import { TerminationReasonsTable } from "../../../../Components/InternalCustomerAdmin/TerminationReasons/TerminationReasonsTable";
import { useTranslation } from "react-i18next";

function TerminationReasonsContainer({ ...props }) {
    const { terminationReasonsActions } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        terminationReasonsActions.getTerminationReasons();
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Header.Title>{t('terminationreasonscontainer.header')}</Card.Header.Title>
            </Card.Header>
            <Card.Content>
                <div className="mb-12">
                    <button className="btn btn-primary" onClick={() => navigate(routes.icaTerminationReasonsCreate)}>{t('terminationreasonscontainer.createreason')}</button>
                </div>
                <div>
                    <TerminationReasonsTable {...props} />
                </div>
            </Card.Content>
        </Card>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        terminationReasonsActions: bindActionCreators(terminationReasonsActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        terminationReasons: state.InternalCustomerAdminReducer.terminationReasons,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminationReasonsContainer);
