import React from 'react';

import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import GuidedSellingContainer from './Settings/GuidedSellingContainer'
import GuidedSellingEditContainer from './Settings/GuidedSellingEditContainer'
import EmailTemplatesContainer from './Settings/EmailTemplatesContainer'
import EmailTemplateEditContainer from './Settings/EmailTemplateEditContainer'
import LeadsListsContainer from './Settings/LeadsListsContainer'
import CustomerInfoTypesContainer from './Settings/CustomerInfoTypesContainer'
import CustomerInfoTypesEditContainer from './Settings/CustomerInfoTypesEditContainer'
import SettingsCompensationContainer from './Settings/SettingsCompensationContainer'
import SettingsCompensationEditContainer from './Settings/SettingsCompensationEditContainer'
import HittaByraEditContainer from './Settings/HittaByraEditContainer'
import QuotationFaqContainer from './Settings/QuotationFaqContainer'
import HittaByraContainer from './Settings/HittaByraContainer';
import HittaPartnerContainer from './Settings/HittaPartnerContainer';
import HittaPartnerEditContainer from './Settings/HittaPartnerEditContainer';
import HittaByraAddDuplicateContainer from './Settings/HittaByraAddDuplicateContainer';
import AdminUsersContainer from './Settings/AdminUsersContainer';
import AdminusersEditContainer from './Settings/AdminUsersEditContainer';
import AdminBudgetContainer from './Settings/AdminBudgetContainer';
import AdminEditBudgetContainer from './Settings/AdminEditBudgetContainer';
import OrderApprovalRequestsContainer from './Settings/OrderApprovalRequestsContainer';
import OrderApprovalRequestsEditContainer from './Settings/OrderApprovalRequestsEditContainer';
import ReleaseNewsEditContainer from './Settings/ReleaseNewsEditContainer';
import ReleaseNewsContainer from "../Components/ReleaseNewsContainer";
import EloquaEditContainer from "./Settings/EloquaEditContainer";
import EloquaDefinitionsContainer from "./Settings/EloquaDefinitionsContainer";
import BokaMeraAdminContainer from "./Settings/BokaMeraAdminContainer";

import { withTranslation } from 'react-i18next';

import './Settings/Settings.css'
import routes from "../Constants/routes";
import PillNavigation from "../Components/Navigation/PillNavigation";
import { UserRoles } from "../Constants/userRoles";
import EditProductVersionContainer from "./EditProductVersionContainer";
import { hasAccess, hasAdminAccess } from "../Utils/permissionUtils";
import RequireAuth from "../Components/Permission/RequireAuth";
import WorkerJobsContainer from "./Settings/WorkerJobsContainer";
import Card, { CardContainer } from "../Components/Card/Card";
import FeatureToggleContainer from './Settings/FeatureToggleContainer';
import TerminationReasonsLayout from '../Components/Layout/InternalCustomerAdmin/TerminationReasonsLayout';
import { FeatureFlags, isFeatureEnabled } from '../Utils/featureFlags';
import ActivityLogContainer from './Settings/InternalCustomerAdmin/ActivityLog/ActivityLogContainer';
import TargetGroupsLayout from "../Components/Layout/Voss/TargetGroupsLayout";

function SettingsContainer({ user, t }) {

    const spcsAdmin = hasAdminAccess(user, UserRoles.Spcs);

    const links = [
        { to: routes.vossTargetGroups, text: "Målgrupper", disabled: !isFeatureEnabled(user, FeatureFlags.VOSS) || !hasAdminAccess(user, UserRoles.Customer)},
        { to: routes.icaTerminationReasons, text: "Uppsägningorsaker", disabled: !isFeatureEnabled(user, FeatureFlags.VOSS) },
        { to: routes.icaLog, text: t("navigation.log")},
        { to: routes.settingsAdminUsers, text: t('navigation.adminusers'), disabled: !hasAccess(user, UserRoles.User) },
        { to: routes.bokameraadmin, text: "BokaMera Orders", disabled: !hasAccess(user, UserRoles.BokaMera) },
        { to: routes.budget, text: t("navigation.budget"), disabled: !spcsAdmin },
        { to: routes.settingsCompensation, text: t('navigation.compensation'), disabled: !hasAccess(user, UserRoles.Compensation) },
        { to: routes.settingsCustomerInfoTypes, text: t('navigation.customerinfotypes'), disabled: !hasAccess(user, UserRoles.Spcs) },
        { to: routes.editProductVersions, text: t('productOverview.editversion'), disabled: !hasAdminAccess(user, UserRoles.ProductOverview) },
        { to: routes.eloqua, text: t('navigation.eloquaMapping'), disabled: !hasAccess(user, UserRoles.Eloqua) },
        { to: routes.settingsEmailTemplates, text: t('navigation.emailtemplates'), disabled: !hasAccess(user, UserRoles.EmailTemplate) },
        { to: routes.settingsGuidedSelling, text: t('navigation.guidedselling'), disabled: !spcsAdmin },
        { to: routes.hittaByra, text: t('navigation.hittabyra'), disabled: !hasAdminAccess(user, UserRoles.AOSales) },
        { to: routes.hittaPartner, text: t('navigation.hittapartner'), disabled: !hasAdminAccess(user, UserRoles.PartnerSales) },
        { to: routes.settingsLeadLists, text: t('navigation.leadslists') },
        { to: routes.orderApprovalRequests, text: t('navigation.orderapprovalrequests'), disabled: !hasAccess(user, UserRoles.OrderApproval) },
        { to: routes.releaseNews, text: t('navigation.releaseNews'), disabled: !hasAccess(user, UserRoles.AdministratorSkynet) },
        { to: routes.settingsQuotationFaq, text: t('navigation.quotationfaq'), disabled: !spcsAdmin },
        { to: routes.settingsWorkerJobs, text: "Jobbigt", disabled: !hasAdminAccess(user, UserRoles.AdministratorSkynet) },
        { to: routes.settingsFeatureToggles, text: "Feature Toggles", disabled: !hasAdminAccess(user, UserRoles.AdministratorSkynet) }
    ];

    return (
        <CardContainer>
            <Card>
                <Card.Header>
                    <PillNavigation user={user} path={window.location.pathname} links={links} />
                </Card.Header>
            </Card>
            <Card>
                <Routes>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.Customer]} />}>
                        <Route path={'/target-groups/*'} element={<TargetGroupsLayout />}/>
                    </Route>
                    <Route path={'/internal-customer-admin/termination-reasons/*'} element={<TerminationReasonsLayout/>}/>
                    <Route path={'/internal-customer-admin/log/*'} element={<ActivityLogContainer/>}/>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.User]} />}>
                        <Route exact path={'/adminusers/'} element={<AdminUsersContainer />} />
                        <Route path={'/adminusers/:type/:id'} element={<AdminusersEditContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.BokaMera]} />}>
                        <Route path={'/bokameraadmin'} element={<BokaMeraAdminContainer />} />
                    </Route>
                    <Route path={'/budget/'} element={<AdminBudgetContainer />} />
                    <Route path={'/editbudget/:id/:type'} element={<AdminEditBudgetContainer />} />
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.Compensation]} />}>
                        <Route exact path={'/compensation/'} element={<SettingsCompensationContainer />} />
                        <Route path={'/compensation/edit/:id/'} element={<SettingsCompensationEditContainer />} />
                    </Route>
                    <Route exact path={'/customerinfotypes/'} element={<CustomerInfoTypesContainer />} />
                    <Route path={'/customerinfotypes/edit/:customerNumber'} element={<CustomerInfoTypesEditContainer />} />
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.ProductOverview]} />}>
                        <Route path={'/editproductversions'} element={<EditProductVersionContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.Eloqua]} />}>
                        <Route path={'/eloqua'} element={<EloquaEditContainer />} />
                        <Route path={'/eloqua/definitions'} element={<EloquaDefinitionsContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.EmailTemplate]} />}>
                        <Route path={'/email-templates/'} element={<EmailTemplatesContainer />} />
                        <Route path={'/email-template/edit/:id'} element={<EmailTemplateEditContainer />} />
                    </Route>
                    <Route path={'/guided-selling/'} element={<GuidedSellingContainer />} />
                    <Route path={'/guided-selling/edit/:id'} element={<GuidedSellingEditContainer />} />
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.AOSales]} />}>
                        <Route exact path={'/hittabyra/'} element={<HittaByraContainer />} />
                        <Route path={'/hittabyra/edit/:id'} element={<HittaByraEditContainer />} />
                        <Route path={'/hittabyra/addduplicate/:id'} element={<HittaByraAddDuplicateContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.PartnerSales]} />}>
                        <Route exact path={'/hittapartner/'} element={<HittaPartnerContainer />} />
                        <Route path={'/hittapartner/:id'} element={<HittaPartnerEditContainer />} />
                    </Route>
                    <Route path={'/lead-lists/'} element={<LeadsListsContainer />} />
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.OrderApproval]} />}>
                        <Route exact path={'/orderapprovalrequests/'} element={<OrderApprovalRequestsContainer />} />
                        <Route path={'/orderapprovalrequests/:id'} element={<OrderApprovalRequestsEditContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.AdministratorSkynet]} />}>
                        <Route exact path={'/release-news/'} element={<ReleaseNewsContainer />} />
                        <Route exact path={'/release-news-edit/:id'} element={<ReleaseNewsEditContainer />} />
                    </Route>
                    <Route path={'/quotationFaq/'} element={<QuotationFaqContainer />} />
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.AdministratorSkynet]} />} >
                        <Route path={'/worker-jobs'} element={<WorkerJobsContainer />} />
                    </Route>
                    <Route element={<RequireAuth.RequireAdminAuth roles={[UserRoles.AdministratorSkynet]} />} >
                        <Route path={'/featuretoggles'} element={<FeatureToggleContainer />} />
                    </Route>
                </Routes>
            </Card>
        </CardContainer>
    )
}


function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
    }
}


export default connect(mapStateToProps, null)(withTranslation()(SettingsContainer));