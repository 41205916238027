import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";
import {useNavigate} from "react-router-dom";

export function TargetGroupsTable({ targetGroups }) {
    const navigate = useNavigate();
    const configurations = [
        {id: "contactPersonRequired", label: "Req. contact person"},
        {id: "customerAddressRequired", label: "Req. customer address"},
        {id: "customerNumberRequired", label: "Req. customer number"},
        {id: "excludeCustomerFromInvoiceFile", label: "Exclude customer from invoice file"},
        {id: "expiryDateRequired", label: "Req. expiry date"},
        {id: "organizationNumberRequired", label: "Req. org. no"},
        {id: "organizationNumberUnique", label: "Unique org. no"}
    ]
    const navigateToEdit = (id) => {
        const params = new URLSearchParams();
        params.append("Id", id);
        navigate(routes.vossTargetGroupsEdit + "?" + params.toString())
    }

    const renderConfiguration = (configuration) => {
        return configurations.map(config => {
            return <td key={config.id} className="text-center">{configuration[config.id] && <SkynetIcon icon="vismaicon-approved pt-4"/> }</td>
        })
    }

    return <table className="table table-stripe skynet-table-no-divider table-active">
        <thead>
            <tr>
                <th>Namn</th>
                {configurations.map(config => {
                    return <th key={config.id} className="text-center">{config.label}</th>
                })}
            </tr>
        </thead>
        <tbody>
        {targetGroups && targetGroups.map((group, index) => {
            return <tr key={group.name} onClick={() => navigateToEdit(group.id)}>
                <td className={group.isDefault ? "bold" : ""}>{group.name}</td>
                {renderConfiguration(group.configuration)}
            </tr>
        })}
        </tbody>
    </table>
}