import { VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";
import { Dropdown } from "../../../FormComponents/Dropdown";

export default function VonEditEmployeeLicensesTable({ data, onChange }) {

    const createDropdownOptions = (account) => {
        let serviceRoles = data.service.vonServiceRoles;
        switch (account.employeeType.toString()) {
            case VonEmployeeTypes.Auditor.id:
                serviceRoles = serviceRoles.filter(role => role.name.endsWith(':auditor'));
                break;
            default:
                serviceRoles = serviceRoles.filter(role => !role.name.endsWith(':auditor'));
                break;
        }

        return [
            { id: '0', displayName: 'Ej tillgång' },
            ...serviceRoles
        ];
    };

    const renderAccountRow = (account) => {
        return <tr>
            <td>{account.name}</td>
            <td>{account.email}</td>
            <td>{Object.values(VonEmployeeTypes).find(x => x.id === account.employeeType.toString()).name}</td>
            <td className="col-3"><Dropdown disabled={disabled && account.roleId == '0'} onChange={(value) => onChangeAccessRight(value, account)} options={createDropdownOptions(account)} value={account.roleId} property="displayName" /></td>
        </tr>
    }

    const onChangeAccessRight = (roleId, account) => {
        data.vonAccounts.find(x => x.id == account.id).roleId = roleId;
        onChange('vonLicenseAdministrationForm', data.vonAccounts, 'vonAccounts');
    }

    const disabled = data.vonAccounts.filter(x => x.roleId != '0').length >= data.service.licenses;

    return <table className="table table-relaxed">
        <thead>
            <tr>
                <th>Namn</th>
                <th>E-postadress</th>
                <th>Typ av användare</th>
                <th>Behörighet</th>
            </tr>
        </thead>
        <tbody>
            {data.vonAccounts.map(account => renderAccountRow(account))}
        </tbody>
    </table>
}