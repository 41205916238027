import VonEditEmployeeLicensesTable from "./VonEditEmployeeLicensesTable";

export default function VonEditEmployeeLicensesForm({ ...props }) {
    const { vonLicenseAdministrationForm, selectedService } = props;

    return <div className="d-grid">
        <VonEditEmployeeLicensesTable data={vonLicenseAdministrationForm.data} {...props} />
        <div>
            <p className="float-right">
                Kunden använder {vonLicenseAdministrationForm.data.vonAccounts.filter(x => x.roleId != '0').length} av {selectedService.licenses} möjliga licenser
            </p>
        </div>
    </div>
}